@media only screen and (max-width: 480px) {

  .langNav {

    &__container {
      
      width: 100%;
      flex-direction: column;
      align-items: flex-end;
      top: 0;
      left: 0;
      padding-top: 15px;
      padding-right: 15px;
  
    }
  
    &__button {
      width: 1.8rem;
      height: 1.8rem;
      margin-right: unset;
      margin-bottom: .3rem;
  
    }
  
  }

}


@media only screen and (min-width: 481px) and (max-width: 850px) and (orientation: landscape)  {

  .langNav {

    &__container {

      position: absolute;
      padding-right: 1.6rem;
      padding-top: 100px;
      left: unset;
      right: 0;
  
    }
  
    &__button {
      width: 1.8rem;
      height: 1.8rem;
      margin-right: unset;
      margin-left: .2rem;
  
    }
  
  }

}


@media only screen and (min-width: 850px) and (max-width: 1366px) {

  .langNav {

    &__container {

      padding-left: 10px;
      padding-top: 30px;

    }
  
    &__button {
      width: 2rem;
      height: 2rem;
      margin-right: unset;
      margin-left: .3rem;
  
    }
  
  }

}