@use "../../styles/vars";

.navbar {

  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;

  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100vh;
  padding-left: 1rem;

  font-size: vars.$navbar-font-size;
  font-weight: vars.$navbar-font-weight;
  text-shadow: 0 0 15px vars.$navbar-text-shadow-color;
  
  &__buttons {

    z-index: 40;
    width: 4rem;
    height: 4rem;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    text-shadow: 0 0 5px vars.$navbar-shadow-color;
    
    border-right: 4px solid vars.$color-white;
    background-size: 200% 200%;
    background-position: left;
    -webkit-transition: background-position 0.5s;
    -moz-transition: background-position 0.5s;
    transition: background-position 0.5s; 


    &--icons {
      width: 100%;
    }

    &--parent {
      position: relative;
      display: flex;
      justify-content: start;
      align-items: center;
    }

    &--parent span {

      z-index: 30;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 6rem;
      padding: 0 1rem;
      
      text-transform: capitalize;
      white-space: nowrap;
      visibility: hidden;
      opacity: 0;
      
    }

    &--domaine {
      border-color: vars.$navbar-btn-domaine;
      background-image: linear-gradient(to left, vars.$navbar-btn-domaine 50%, vars.$navbar-btn-main 0);

      &-label {
        min-height: 100%;
        color: vars.$color-white;
        background-color: rgba(vars.$color-black, .9);
      }

      &-selected {
        border-color: vars.$navbar-btn-domaine;
        background-color: vars.$navbar-btn-domaine;
      }

    }

    &--vins {
      border-color: vars.$navbar-btn-vins;
      background-image: linear-gradient(to left, vars.$navbar-btn-vins 50%, vars.$navbar-btn-main 0);

      &-label {
        min-height: 100%;
        color: vars.$color-white;
        background-color: rgba(vars.$color-black, .9);
      }

      &-selected {
        border-color: vars.$navbar-btn-vins;
        background-color: vars.$navbar-btn-vins;
      }

    }

    &--boutique {
      border-color: vars.$navbar-btn-boutique;
      background-image: linear-gradient(to left, vars.$navbar-btn-boutique 50%, vars.$navbar-btn-main 0);

      &-label {
        min-height: 100%;
        color: vars.$color-white;
        background-color: rgba(vars.$color-black, .9);
      }

      &-selected {
        border-color: vars.$navbar-btn-boutique;
        background-color: vars.$navbar-btn-boutique;
      }
    
    }

    &--news {
      border-color: vars.$navbar-btn-news;
      background-image: linear-gradient(to left, vars.$navbar-btn-news 50%, vars.$navbar-btn-main 0);

      &-label {
        min-height: 100%;
        color: vars.$color-white;
        background-color: rgba(vars.$color-black, .9);
      }

      &-selected {
        border-color: vars.$navbar-btn-news;
        background-color: vars.$navbar-btn-news;
      }

    }

    &--contact {
      border-color: vars.$navbar-btn-contact;
      background-image: linear-gradient(to left, vars.$navbar-btn-contact 50%, vars.$navbar-btn-main 0);

      &-label {
        min-height: 100%;
        color: vars.$color-white;
        background-color: rgba(vars.$color-black, .9);
      }

      &-selected {
        border-color: vars.$navbar-btn-contact;
        background-color: vars.$navbar-btn-contact;
      }

    }

  }

  &__buttons:hover {
    background-position: right;
  }

  &__buttons:hover + span {
    // background-position: left;
    visibility: visible;
    opacity: 1;
  }

}

ul {
  list-style: none;
  padding-left: 0;
}

@import './responsive.scss';