@use "../../styles/vars";

.boutique {

  &__wrapper {

    min-width: 100vw;
    min-height: 100vh;
    box-sizing: border-box;
    padding: 3rem;
    padding-left: 10vw;
    padding-top: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: vars.$color-boutique-main;

  }

  &__logo {

    position: absolute;
    top: 10px;
    right: 20px;
    width: 150px;
    height: 150px;
    background-image: url(../../assets/images/PageLogo-B.png);
    background-repeat: no-repeat;
    background-size: contain;

  }

  &__cart {

    &--container {

      position: relative;
      width: auto;
      align-self: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 4rem;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      div:last-child {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translatex(-50%);
        font-size: .9rem;

        button {
          padding: 6px 12px;
          border-radius: 5px;
          color: vars.$color-boutique-main;
          font-size: .9rem;
          border: 0;
          background-color: vars.$color-Elixir-D;
        }

      }

      span { 
        padding-bottom: .6rem;
        text-decoration: underline;
        text-underline-offset: .4rem;
      }

      span:last-child {
        text-decoration: unset;
      }

    }

    &--image {    
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      width: 2.2rem;
      height: 6rem;
      padding-bottom: 10px;
      font-size: 1rem;
      background-image: url(../../assets/icons/boutique-cart-empty.png);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center left;
    }
    
  }

  &__cards--wrapper {

    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

  }

  &__card {

      width: calc(((100vw - 10vw) / 4) - (3vw * 2));
      margin-left: 3vw;
      margin-bottom: 2rem;
      padding: .8rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      color: vars.$color-Elixir-D;
      box-shadow: 0 0 15px rgba(vars.$color-black, .15);

    &--header {
      width: 100%;

      span {
        font-size: .8rem;
      }

      span:first-child {
        font-size: unset;
      }

      span:last-child {
        display: none;
      }
    }

    &--main {


      min-width: 100%;
    }

    &--image {

      min-width: 100%;
      min-height: 300px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;

      &-Elixir {
        background-image: url(../../assets/images/Wine-BottleElixir.png);
      }

      &-Godefroy {
        background-image: url(../../assets/images/Wine-BottleGodefroy.png);
      }

      &-Brouilly {
        background-image: url(../../assets/images/Wine-BottleBrouilly.png);
      }

      &-CDB {
        background-image: url(../../assets/images/Wine-BottleCDB.png);
      }

      &-SansArtifice {
        background-image: url(../../assets/images/Wine-BottleSansArtifice.png);
      }

      &-Passion {
        background-image: url(../../assets/images/Wine-BottlePassion.png);
      }

    }

    &--details {

      min-width: 100%;
      margin-bottom: .6rem;

      span {
        font-size: .7rem;
      }

      span:first-child {
        font-size: .9rem;
      }

    }

    &--price {

      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: .9rem;

      div:first-child {
        display: flex;
        align-items: flex-start;
      }

      span:nth-child(2) {
        font-size: .8rem;
        align-self: flex-end;
      }

      span:last-child {
        font-size: .7rem;
      }

    }

    &--buttons {

      display: flex;
      align-items: center;

      span { 
        
        display: flex;
        justify-content: center;
        min-width: 1.2rem;
        padding: .5rem;

      }

      button {

        width: 1.3rem;
        height: 1.3rem;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        border: 0;

        &:first-child {
          background-image: url(../../assets/icons/boutique-cart-add.png);
        }

        &:last-child {
          background-image: url(../../assets/icons/boutique-cart-remove.png);
        }

      }

    }

  }

}

@import "./responsive.scss";