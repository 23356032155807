@use "../../styles/vars";

video {

  width: 100%;
  height: 100vh;
  object-fit: cover;

}

.container-hero {

  width: 100vw;
  min-height: 100vh;
  padding: 0px;
  margin: 0;
  overflow: hidden;
  background-image: url(../../assets/images/HeroPage-VideoBG.jpg);
  background-position: center center;
  background-size: cover;

  &__filter-container {

    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
  }

  &__video-container {

    width: 100%;
    height: 100vh;
    padding: 0px;
    margin: 0;

  }

  &__logo-container {

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin: 0;

    /*
    background-image: url(../../assets/images/HeroLogo.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 20%;

    border: 5px solid #fff;
    border-color: rgba(255,255,255, 0.3);
    border-radius: 1rem;
    */
  }

  button.hero-logo-link {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
  }

  &__logo {
    width: 480px;
    height: 480px;
  }

  button.hero-logo-link svg,
  button.hero-logo-link svg rect {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    fill: transparent;
  }

  button.hero-logo-link svg rect {
    stroke: #9F2032;
    stroke-width: 1;
    transition: 1.5s;
    stroke-dasharray: 1928;
    stroke-dashoffset: 1928;
  }

  button.hero-logo-link:hover svg rect {
    stroke: #E98B2E;
    stroke-width: 10;
    stroke-dashoffset: 0;
  }

}

@import './responsive.scss';