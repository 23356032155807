@media only screen and (max-width: 480px) {

  .contactpage {

    &__wrapper {
      
      isolation: isolate;
      padding-bottom: calc(100vw/5);
      overflow-x: hidden;
      
    }
  
    &__logo {
  
      left: 0;
      width: 90px;
      height: 90px;
      margin-left: 30px;
      position: absolute;
  
    }
  
    &__container {
      
      width: 90vw;
      margin-left: unset;
      padding: 1.5rem;
      padding-top: 130px;

      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
  
    }

    &__col-right {
  
      &--sent-message {

        min-height: 20vh;
        
        ::before {
          background-size: 80%;
          background-position: center center;
          opacity: 0.1;
        }
  
      }
  
    }

    &__col-right, &__col-left {
  
      width: 90%;
  
    }
  
    &__container h1 {
  
      max-width: unset;
      font-size: 2.5rem;
  
    }
  
    &__container h2 {

      max-width: unset;
      font-size: 1.5rem;
  
    }
  
    &__container p {
  
      font-size: 1rem;
      
    }

    &__form {

      max-width: unset;

    }
  
    &__form input, &__form textarea, &__form input::placeholder  {
      
      font-size: 1rem;
  
    }
    
    &__form textarea {
      height: 200px;
    }
  
    &__form div:last-child {
  
      display: flex;
      justify-content: flex-end;

    }

    &__map {

      background-size: 200%;
  
    }  
  
  }

}

@media only screen and (min-width: 481px) and (max-width: 850px) {

  .contactpage {
  
    &__logo {
  
      width: 130px;
      height: 130px;
  
    }
  
    &__container {
      
      width: 80vw;
      margin-left: unset;
      padding: 1.5rem;
      padding-top: 130px;

      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
  
    }

    &__col-right {
  
      &--sent-message {

        min-height: 60vh;
        
        ::before {
          background-size: 50%;
          background-position: center left;
          opacity: 0.1;
        }
  
      }
  
    }

    &__col-right, &__col-left {
  
      width: 90%;
  
    }
  
    &__container h1 {
  
      max-width: unset;
      font-size: 2.5rem;
  
    }
  
    &__container h2 {

      max-width: unset;
      font-size: 1.5rem;
  
    }
  
    &__container p {
  
      font-size: 1rem;
      
    }

    &__form {

      max-width: 65vw;

    }
  
    &__form input, &__form textarea, &__form input::placeholder  {
      
      font-size: 1rem;
  
    }

    &__form textarea {
      height: 230px;
    }
  
    &__form div:last-child {
  
      display: flex;
      justify-content: flex-end;

    }

    &__map {
      
      height: 70vh;;
  
    }    
  
  }

}


@media only screen and (min-width: 850px) and (max-width: 1366px) {

  .contactpage {
  
    &__logo {
  
      width: 150px;
      height: 150px;
  
    }
  
    &__container {
      
      margin-left: unset;
      padding: 1.5rem;
      padding-top: 125px;
  
    }
  
    &__container h1 {
  
      font-size: 3rem;
  
    }
  
    &__container h2 {
  
      font-size: 1.5rem;
  
    }
  
    &__container p {
  
      font-size: 1rem;
      
    }

    &__col-right {
  
      &--sent-message {

        min-height: 35vh;
        
        ::before {
          background-size: 60%;
          background-position: center left 40px;
          opacity: 0.1;
        }
  
      }
  
    }

    &__form {
      max-width: unset;
    }
  
    &__form input, &__form textarea, &__form input::placeholder  {
      
      font-size: 1rem;
  
    }

    &__form textarea {
      height: 180px;
    }
  
    &__form div:last-child {
  
      display: flex;
      justify-content: flex-end;

    }
  
  
  }

}