/* ----- FONTS ----- */
$font: 'Roboto';

/* ----- Style Navbar, Header, Carousel ----- */
$navbar-btn-main: RGBA(0, 0, 0, .4);

$navbar-btn-domaine: RGBA(204, 89, 67, .6);
$navbar-btn-vins: RGBA(233, 139, 46, .6);
$navbar-btn-news: RGBA(136, 129, 56, .6);
$navbar-btn-boutique: RGBA(138, 80, 40, .6);
$navbar-btn-contact: RGBA(159, 32, 50, .6);

$navbar-label-domaine: RGBA(204, 89, 67, .9);
$navbar-label-vins: RGBA(233, 139, 46, .9);
$navbar-label-news: RGBA(136, 129, 56, .9);
$navbar-label-boutique: RGBA(159, 32, 50, .9);
$navbar-label-contact: RGBA(138, 80, 40, .9);

$navbar-text-shadow-color: rgba(25, 25, 25, .4);
$navbar-shadow-color: rgba(25, 25, 25, .2);

$navbar-font-size: 2rem;
$navbar-font-weight: 300;

/* ----- COLORS ----- */
$color-pink : #cc5943;
$color-yellow : #E98B2E;
$color-green : #587128;
$color-red : #9F2032;
$color-brown : #8a5028;
$color-white: #fefefe;
$color-black: #151515;
$color-dgrey: #33302F;
$color-ngrey: #777777;
$color-lgrey: #999;
$color-Elixir-D: #181C30;
$color-Elixir-L: #283556;
$color-Godefroy-D: #a89a1a;
$color-Godefroy-L: #e5da5e;
$color-CDB-D: #44515c;
$color-CDB-L: #646d6c;
$color-Brouilly-D: #7a1a31;
$color-Brouilly-L: #9f2442;
$color-Passion-D: #cfb0ae;
$color-Passion-L: #eecdcb;
$color-SansArtifice-D: #a49a95;
$color-SansArtifice-L: #babcc1;
$color-boutique-main: #F3F0EB;


$color-hve: #B4CB60;

/* ----- BOX-SHADOW ------ */
$shadow-color: rgba(25, 25, 25, 0.3);

/* ----- MODAL STYLING ------ */
$modal-bg-color: RGBA(138, 80, 40, .8);

/* ----- RESPONSIVE VARS -----*/
$mobile-padding-bottom: calc((100vw / 5) + 3rem);