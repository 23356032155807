@use '../../styles/vars';


.winespage {

  &__controls {

    position: fixed;
    top:50%;
    transform: translateY(-50%);
    right: 0;

  }

  &__controls li {

    z-index: 20;
    position: relative;
    width: 5rem;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(vars.$color-black, .6);
    cursor: pointer;

  }

  &__controls li:hover {
    background: rgba(vars.$color-black, 1)
  }

  &__controls li:before {

    content: "";
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    border-left: 3px solid vars.$color-white;
    border-bottom: 3px solid vars.$color-white;
    transform: rotate(45deg);
    margin-left: .6rem;

  }

  &__controls li:last-child:before {
    transform: rotate(225deg);
    margin-left: unset;
    margin-right: .6rem;
  }
  
  &-wrapper {

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: 200%;

  }

  &__logo {

    position: fixed;
    top: 0;
    right: 0;
    margin: 1rem;
    width: 180px;
    height: 180px;

    background-image: url(../../assets/images/PageLogo-W.png);
    background-size: contain;
    background-repeat: no-repeat;

  }

  &__bottles {

    &--container {

      display: none;

      &-active {

        display: block;

      }
      
    }

    &--bottle {
    
      &-container {

        min-width: 100vw;
        height: 105vh;
        background-position: top center;
        background-size: contain;
        background-repeat: no-repeat;

        &-Elixir {
          background-color: vars.$color-Elixir-D;
          background-image: url(../../assets/images/Wine-BottleElixir.png), 
                            radial-gradient(circle, vars.$color-Elixir-L, vars.$color-Elixir-D);
        }

        &-Godefroy {
          background-color: vars.$color-Godefroy-D;
          background-position: top center;
          background-image: url(../../assets/images/Wine-BottleGodefroy.png), 
                            radial-gradient(circle, vars.$color-Godefroy-D, vars.$color-Godefroy-L);
        }

        &-Brouilly {
          background-color: vars.$color-Brouilly-D;
          background-position: top center;
          background-image: url(../../assets/images/Wine-BottleBrouilly.png), 
                            radial-gradient(circle, vars.$color-Brouilly-D, vars.$color-Brouilly-L);
        }

        &-CDB {
          background-color: vars.$color-CDB-D;
          background-position: top center;
          background-image: url(../../assets/images/Wine-BottleCDB.png), 
                            radial-gradient(circle, vars.$color-CDB-L, vars.$color-CDB-D);
        }

        &-Passion {
          background-color: vars.$color-Passion-D;
          background-position: top center;
          background-image: url(../../assets/images/Wine-BottlePassion.png), 
                            radial-gradient(circle, vars.$color-Passion-L, vars.$color-Passion-D);
        }

        &-SansArtifice {
          background-color: vars.$color-SansArtifice-D;
          background-position: top center;
          background-image: url(../../assets/images/Wine-BottleSansArtifice.png), 
                            radial-gradient(circle, vars.$color-SansArtifice-L, vars.$color-SansArtifice-D);
        }
      
      }

    }

    &--info {

      &-container {

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        padding-bottom: 6rem;
        bottom: 0;

        &-Elixir {
          border-top: 2px solid vars.$color-Elixir-D;
          background: linear-gradient(vars.$color-Elixir-L,  vars.$color-Elixir-D);
          box-shadow: 0 5px 25px rgba(vars.$color-Elixir-D, .8); 
        }

        &-Godefroy {
          border-top: 2px solid vars.$color-Godefroy-D;
          background: linear-gradient(vars.$color-Godefroy-L,  vars.$color-Godefroy-L);
          box-shadow: 0 5px 25px rgba(vars.$color-black, .8); 
        }

        &-Brouilly {
          border-top: 2px solid vars.$color-Brouilly-D;
          background: linear-gradient(vars.$color-Brouilly-D,  vars.$color-Brouilly-L);
          box-shadow: 0 5px 25px rgba(vars.$color-black, .8); 
        }

        &-CDB {
          border-top: 2px solid vars.$color-CDB-D;
          background: linear-gradient(vars.$color-CDB-D,  vars.$color-CDB-L);
          box-shadow: 0 5px 25px rgba(vars.$color-black, .8); 
        }

        &-Passion {
          border-top: 2px solid vars.$color-Passion-D;
          background: linear-gradient(vars.$color-Passion-D,  vars.$color-Passion-L);
          box-shadow: 0 5px 25px rgba(vars.$color-black, .8); 
        }

        &-SansArtifice {
          border-top: 2px solid vars.$color-SansArtifice-D;
          background: linear-gradient(vars.$color-SansArtifice-D,  vars.$color-SansArtifice-L);
          box-shadow: 0 5px 25px rgba(vars.$color-black, .8); 
        }
        
        //* ----- Color for the LI list EM tabgs  ----------------------------------------
        em {
          font-style: normal;
          font-weight: 500;
          color: vars.$color-red;
          text-transform: uppercase;
        }

          em.Elixir {
            color: vars.$color-hve;
          }

          em.Godefroy {
            color: vars.$color-red;
          }

          em.Brouilly {
            color: vars.$color-yellow;
          }

          em.CDB {
            color: vars.$color-Elixir-D;
          }

          em.Passion {
            color: vars.$color-pink;
          }

          em.SansArtifice {
            color: vars.$color-dgrey;
          }

        p > em {
          line-height: 2rem;
        }

        p {          
          width: 70vw;
          margin-bottom: 2rem;
          font-weight: 300;
          font-size: 1.4rem;
          line-height: 1.8rem;
          letter-spacing: .05rem;
          text-align: justify;
        }

          p.Elixir {
            background: -webkit-linear-gradient(#fefefe, #cecece);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          p.Godefroy {
            background: -webkit-linear-gradient(vars.$color-red, #111);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          p.Brouilly {
            background: -webkit-linear-gradient(#fefefe, #cecece);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          p.CDB {
            background: -webkit-linear-gradient(#fefefe, #cecece);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          p.Passion {
            background: -webkit-linear-gradient(#fefefe, #F0F0F0);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          p.SansArtifice {
            background: -webkit-linear-gradient(#fefefe, #F0F0F0);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

      }

      &__association-list {
        margin-top: 0;
        padding-left: 1.5rem;
        list-style-type: circle;
      }

      &__association-list > li {
        padding-bottom: .2rem;
      }

      &__association-list > li::first-letter {
        text-transform: capitalize; 
      }

      &-title {

        padding-top: 5rem;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
        overflow: hidden;

        &-Elixir {
          min-width: 380px;
          height: 180px;
          background-image: url(../../assets/images/Wine-TitleElixir.png);
        }

        &-Godefroy {
          min-width: 580px;
          height: 180px;
          background-image: url(../../assets/images/Wine-TitleGodefroy.png);
        }

        &-Brouilly {
          min-width: 580px;
          height: 180px;
          background-image: url(../../assets/images/Wine-TitleBrouilly.png);
        }

        &-CDB {
          min-width: 580px;
          height: 180px;
          background-image: url(../../assets/images/Wine-TitleCDB.png);
        }

        &-Passion {
          min-width: 580px;
          height: 180px;
          background-image: url(../../assets/images/Wine-TitlePassion.png);
        }

        &-SansArtifice {
          min-width: 580px;
          height: 180px;
          background-image: url(../../assets/images/Wine-TitleSansArtifice.png);
        }

      }

      &-container > h3 {
        width: 70vw;
        margin-top: 3rem;
        margin-bottom: 0;
        font-weight: 500;
        font-size: 1.6rem;
      }

      //* ----- Colors for the HR3 for the wine Information --------------------------- 

        &-container > h3.Elixir {
          color: vars.$color-hve;
        }

        &-container > h3.Godefroy {
          color: vars.$color-red;
        }

        &-container > h3.Brouilly {
          color: vars.$color-yellow;
        }

        &-container > h3.CDB {
          color: vars.$color-Elixir-D;
        }

        &-container > h3.Passion {
          color: vars.$color-pink;
        }

        &-container > h3.SansArtifice {
          color: vars.$color-dgrey;
        }

      &-container > aside > ul {
        width: 70vw;
        font-size: 1.4rem;
        font-weight: 100;

        li {
          padding-left: 1rem;
          line-height: 2.8rem;;
        }

      }

      &-container > aside > ul.Elixir, 
      &-container > aside > ul.Brouilly, 
      &-container > aside > ul.CDB, 
      &-container > aside > ul.Passion,
      &-container > aside > ul.SansArtifice {
        color: vars.$color-white;
      }

      //* ----------------------------------------------------------------------
      //* This is the CSS for the Characteristics of the wine ------------------ 
      &-container > aside > ul > li > ul > li > span {

        display: inline-block;
        width: .8rem;
        height: .8rem;
        margin-left: .5rem;
        border-radius: 50%;
      
      }

      &-container > aside > ul > li > ul > li > em {
        font-weight: 300;
        display: inline-block;
        min-width: 10vw;
        text-transform: capitalize;
      }

      &-container > aside > ul > li > ul > li > span {
        border: 1px solid transparent;
      }

        //* ----- Dot Notation for Elixir --------------------------------------------
        &-container > aside > ul > li > ul > li > em.Elixir {
          color: vars.$color-white;
          } 

          &-container > aside > ul > li > ul > li > span.Elixir {
            background-color: vars.$color-hve;
            border-color: vars.$color-hve;
          } 

          &-container > aside > ul > li > ul > li > span.emptyElixir {
            background-color: transparent;
            border-color: vars.$color-hve;
          } 

        //* ----- Dot Notation for Godefroy --------------------------------------------
        &-container > aside > ul > li > ul > li > em.Godefroy {
          color: vars.$color-black;
          } 

          &-container > aside > ul > li > ul > li > span.Godefroy {
            background-color: vars.$color-red;
            border-color: vars.$color-red;
          } 

          &-container > aside > ul > li > ul > li > span.emptyGodefroy {
            background-color: transparent;
            border-color: vars.$color-red;
          } 

        //* ----- Dot Notation for Brouilly --------------------------------------------
        &-container > aside > ul > li > ul > li > em.Brouilly {
          color: vars.$color-white;
          } 

          &-container > aside > ul > li > ul > li > span.Brouilly {
            background-color: vars.$color-yellow;
            border-color: vars.$color-yellow;
          } 

          &-container > aside > ul > li > ul > li > span.emptyBrouilly {
            background-color: transparent;
            border-color: vars.$color-yellow;
          } 

        //* ----- Dot Notation for Cote de Brouilly : CDB --------------------------------------------
        &-container > aside > ul > li > ul > li > em.CDB {
          color: vars.$color-white;
          } 

          &-container > aside > ul > li > ul > li > span.CDB {
            background-color: vars.$color-Elixir-D;
            border-color: vars.$color-Elixir-D;
          } 

          &-container > aside > ul > li > ul > li > span.emptyCDB {
            background-color: transparent;
            border-color: vars.$color-Elixir-D;
          } 

        //* ----- Dot Notation for Passion --------------------------------------------
        &-container > aside > ul > li > ul > li > em.Passion {
          color: vars.$color-white;
          } 

          &-container > aside > ul > li > ul > li > span.Passion {
            background-color: vars.$color-pink;
            border-color: vars.$color-pink;
          } 

          &-container > aside > ul > li > ul > li > span.emptyPassion {
            background-color: transparent;
            border-color: vars.$color-pink;
          } 

        //* ----- Dot Notation for Sans Artifice --------------------------------------------
        &-container > aside > ul > li > ul > li > em.SansArtifice {
          color: vars.$color-white;
          } 

          &-container > aside > ul > li > ul > li > span.SansArtifice {
            background-color: vars.$color-dgrey;
            border-color: vars.$color-dgrey;
          } 

          &-container > aside > ul > li > ul > li > span.emptySansArtifice {
            background-color: transparent;
            border-color: vars.$color-dgrey;
          } 
      
      &-hve {

        &__container {
          width: 70%;
          box-sizing: border-box;
          padding-left: 1.5rem;
        }

        height: 110px;
        margin-top: 3rem;
        padding-left: 130px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-position: center left;
        background-size: contain;
        background-repeat: no-repeat;
        color: vars.$color-hve;
        text-align: left;
        font-size: 1.6rem;
        font-weight: 300;

        &-Elixir{
          background-image: url(../../assets/images/HVE.png);
          color: vars.$color-hve;
        }

        &-Godefroy{
          background-image: url(../../assets/images/HVE-R.png);
          color: vars.$color-red;
        }

        &-Brouilly{
          background-image: url(../../assets/images/HVE-Y.png);
          color: vars.$color-yellow;
        }

        &-CDB{
          background-image: url(../../assets/images/HVE-DB.png);
          color: vars.$color-Elixir-D;
        }

        &-Passion{
          background-image: url(../../assets/images/HVE-P.png);
          color: vars.$color-pink;
        }

        &-SansArtifice{
          background-image: url(../../assets/images/HVE-DG.png);
          color: vars.$color-dgrey;
        }
      
      }

    }

  }

}

@import './responsive.scss';