@media only screen and (max-width: 480px) {
  
  .navbar {

    width: 100%;
    padding-left: 0;
    height: unset;

    &__container {
      position: fixed;   
      bottom: 0;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: row;
      background-color: vars.$color-dgrey;
      box-shadow: 0 0 15px rgba(vars.$color-dgrey, .8);
    }
    
    &__buttons {

      margin: 0;
      width: unset;
      min-width: calc(100vw / 5);
      height: calc(100vw / 5);
      padding: .4rem 0;
      padding-bottom: env(safe-inset-area-bottom, .6rem);
      background-position: left;

      border-right: none;
      border-top: 4px solid vars.$color-white;
      background-position: bottom;


      &--icons {
        width: 50%;    
      }

      &--parent span {

        opacity: 0;
        
      }

      &--domaine {
        border-color: vars.$navbar-btn-domaine;
        background-image: linear-gradient(to bottom, vars.$navbar-btn-domaine 50%, vars.$navbar-btn-main 0);

        &-selected {
          border-color: vars.$navbar-btn-domaine;
          background-color: vars.$navbar-btn-domaine;
        }

      }

      &--vins {
        border-color: vars.$navbar-btn-vins;
        background-image: linear-gradient(to bottom, vars.$navbar-btn-vins 50%, vars.$navbar-btn-main 0);

        &-selected {
          border-color: vars.$navbar-btn-vins;
          background-color: vars.$navbar-btn-vins;
        }

      }

      &--boutique {
        border-color: vars.$navbar-btn-boutique;
        background-image: linear-gradient(to bottom, vars.$navbar-btn-boutique 50%, vars.$navbar-btn-main 0);

        &-selected {
          border-color: vars.$navbar-btn-boutique;
          background-color: vars.$navbar-btn-boutique;
        }
      }

      &--news {
        border-color: vars.$navbar-btn-news;
        background-image: linear-gradient(to bottom, vars.$navbar-btn-news 50%, vars.$navbar-btn-main 0);

        &-selected {
          border-color: vars.$navbar-btn-news;
          background-color: vars.$navbar-btn-news;
        }
      }

      &--contact {
        border-color: vars.$navbar-btn-contact;
        background-image: linear-gradient(to bottom, vars.$navbar-btn-contact 50%, vars.$navbar-btn-main 0);

        &-selected {
          border-color: vars.$navbar-btn-contact;
          background-color: vars.$navbar-btn-contact;
        }
      }

    }

    &__buttons:hover {
      background-position: top;
    }

    &__buttons:hover + span {
      opacity: 0;
    }

  }

}


@media only screen and (min-width: 481px) and (max-width: 850px) and (orientation: landscape)  {
  
  .navbar {

    font-size: 1.6rem;
    padding-left: 0;
    
    &__buttons {
      
      width: 2.6rem;
      height: 2.6rem;

      &--icons {
        width: 120%;
      }

      &--parent span {
  
        left: calc(100vh / 5);
        
      }

    }

  }

}


@media only screen and (min-width: 850px) and (max-width: 1366px) {
  
  .navbar {

    font-size: 1.6rem;
    padding-left: 0;
    
    &__buttons {
      
      width: 3rem;
      height: 3rem;

      &--icons {
        width: 120%;
      }

      &--parent span {
  
        left: 5rem;
        
      }

    }

  }

}