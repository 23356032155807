@media only screen and (max-width: 480px) {

  .winespage {

    &__controls {
  
      z-index: 100;
      position: fixed;
      transform: unset;
      min-width: 100vw;

    }

    &__controls ul {

      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

    }

    &__controls li {
    width: 3rem;
    height: 3rem;
    } 

  &__controls li:before {
    width: .8rem;
    height: .8rem;
    margin-left: .3rem;
  }

  &__controls li:last-child:before {
    margin-right: .3rem;
  }
  
    &__logo {
  
      left: 0;
      margin: .4rem;
      width: 110px;
      height: 110px;
  
    }
  
    &__bottles {
  
      &--bottle {
      
        &-container {
          
          height: 85vh;
          background-position: top 10vh center;

          &-Elixir {
            background-color: vars.$color-Elixir-D;
            background-position: top center;
            background-image: url(../../assets/images/Wine-BottleElixir.png), 
                              radial-gradient(circle, vars.$color-Elixir-L, vars.$color-Elixir-D);
          }
  
          &-Godefroy {
            background-color: vars.$color-Godefroy-D;
            background-position: top center;
            background-image: url(../../assets/images/Wine-BottleGodefroy.png), 
                              radial-gradient(circle, vars.$color-Godefroy-L, vars.$color-Godefroy-D);
          }
  
          &-Brouilly {
            background-color: vars.$color-Brouilly-D;
            background-position: top center;
            background-image: url(../../assets/images/Wine-BottleBrouilly.png), 
                              radial-gradient(circle, vars.$color-Brouilly-L, vars.$color-Brouilly-D);
          }
  
          &-CDB {
            background-color: vars.$color-CDB-D;
            background-position: top center;
            background-image: url(../../assets/images/Wine-BottleCDB.png), 
                              radial-gradient(circle, vars.$color-CDB-L, vars.$color-CDB-D);
          }
  
          &-Passion {
            background-color: vars.$color-Passion-D;
            background-position: top center;
            background-image: url(../../assets/images/Wine-BottlePassion.png), 
                              radial-gradient(circle, vars.$color-Passion-L, vars.$color-Passion-D);
          }
  
          &-SansArtifice {
            background-color: vars.$color-SansArtifice-D;
            background-position: top center;
            background-image: url(../../assets/images/Wine-BottleSansArtifice.png), 
                              radial-gradient(circle, vars.$color-SansArtifice-L, vars.$color-SansArtifice-D);
          }
        
        }
  
      }

      &--info {

        &-container {

          min-height: 175vh!important;

          p > em {
            line-height: 0rem;
          }
          
        }

        &-title {
          margin-top: 1rem;
          padding: 2rem 0;

          &-Elixir,
          &-Godefroy,
          &-Brouilly,
          &-CDB,
          &-Passion,
          &-SansArtifice {
            min-width: 90vw;
            max-height: 8vh;
            background-size: 70vw;
          }

          &-Passion {
            background-size: 80vw;
          }
          
          &-SansArtifice {
            background-size: 80vw;
          }
  
        }
  
        &-container > p {
          width: 85vw;
          font-size: .9rem;
          line-height: 1.3rem;
          letter-spacing: .05rem;
          text-align: justify;
        }
  
        &-container > h3 {
          width: 85vw;
          padding: 0;
          margin-top: 0;
          font-size: 1.2rem;
        }
    
        &-container > p > ul {
          text-align: left;
        }
    
        &-container > aside > ul {
          width: 85vw;
          font-size: .9rem;
          font-weight: 200;

          li {
            padding-left: 1rem;
            line-height: 1.8rem;
          }
  
        }

        &-container > aside > ul > li > ul > li > em {
          font-weight: 300;
          display: inline-block;
          min-width: 22vw;
          text-transform: capitalize;
        }

        //* ----------------------------------------------------------------------
        //* This is the CSS for the Characteristics of the wine ------------------ 
        &-container > aside > ul > li > ul > li > span {
  
          width: .6rem;
          height: .6rem;
        
        }
  
        &-container > aside > ul > li > ul > li > span {
          border: 1px solid transparent;
        }

        &-hve {

          &__container {
            padding-left: unset;
          }
          
          font-size: 1.1rem;
          height: 80px;
          padding-left: 100px;
        }
  
      }
  
    }
  
  }

}


@media only screen and (min-width: 481px) and (max-width: 850px) {
  
  .winespage {

    &__controls li {
  
      width: 4rem;
      height: 4rem;
      
    }
  
    &__controls li:before {
      width: 1rem;
      height: 1rem;
  
    }

    &__logo {
      margin: .4rem;
      width: 100px;
      height: 100px;
    }

    &__bottles {

      &--info {
  
        &-container > p {
          font-size: .9rem;
          line-height: 1.3rem;
          letter-spacing: .05rem;
          text-align: justify;
        }
  
        &-container > h3 {
          padding: 0;
          margin-top: 2rem;
        }
    
        &-container > aside > ul {
          font-size: .9rem;
          font-weight: 100;
  
        }

        &-container > aside > ul {

          li {
            padding-left: 1rem;
            line-height: 2rem;;
          }
  
        }

        //* ----------------------------------------------------------------------
        //* This is the CSS for the Characteristics of the wine ------------------ 
        &-container > aside > ul > li > ul > li > span {
  
          width: .6rem;
          height: .6rem;
        
        }
  
        &-container > aside > ul > li > ul > li > span {
          border: 1px solid transparent;
        }

        &-hve {
          height: 90px;
          padding-left: 110px;
        }
  
      }

    }

  }

}


@media only screen and (min-width: 850px) and (max-width: 1366px) {
  
  .winespage {

    &__logo {
      width: 130px;
      height: 130px;
    }

    &__controls li {
  
      width: 4rem;
      height: 4rem;
  
    }

    &__controls li:before {
  
      width: 1.1rem;
      height: 1.1rem;
      margin-left: .7rem;
  
    }

    &__controls li:last-child:before {
      margin-right: .7rem;
    }

    &__bottles {

      &--info {

        &-container > p {
          font-size: 1rem;
        }
  
        &-container > h3 {
          padding: 0;
          margin-top: 0;
          font-size: 1.4rem;
        }
    
        &-container > aside > ul {
          font-size: 1rem;
          font-weight: 200;

          li {
            padding-left: 1rem;
            line-height: 2rem;
          }
        
        }

        &-hve {
          height: 90px;
          padding-left: 110px;
        }

      }

    }

  }

}