@media only screen and (max-width: 480px) {

  .boutique {
    
    &__wrapper {

      padding: 0;
      justify-content: center;
      align-items: center;
      padding-bottom: vars.$mobile-padding-bottom;

    }

    &__logo { 

      right: 0;
      left: 20px;
      width: 90px;
      height: 90px;

    }

    &__cart {

      &--container {

        position: fixed;
        width: 100vw;
        height: 5.5rem;
        bottom: 20px;
        padding: 0;
        display: flex;
        justify-content: flex-start;
        background-color: rgba(vars.$color-boutique-main, 0.9);

        div:last-child {
          bottom: 15px;
          font-size: .9rem;      
        }

      }

      &--image {
        height: 3rem;
      }

    }

    &__cards--wrapper {

      justify-content: center;
      align-items: center;
      margin-top: 120px;

    }

    &__card {

      width: 80vw;
      margin:0;
      margin-bottom: 2rem;

    }

  }

}


@media only screen and (min-width: 481px) and (max-width: 850px) and (orientation: landscape)  {

  .boutique {
    
    &__wrapper {

      padding: 0;
      justify-content: center;
      align-items: center;
      padding-bottom: 50px;

    }

    &__logo { 
      
      width: 90px;
      height: 90px;

    }

    &__cart {

      &--container {

        padding: 0;
        margin-top: 12vh;

      }

    }

    &__cards--wrapper {

      justify-content: center;
      align-items: center;

    }

    &__card {

      width: calc(((80vw - 10vw) / 2) - (3vw * 2));
      margin-left: 8vw;
      margin-bottom: 2rem;

    }

  }

}


@media only screen and (min-width: 850px) and (max-width: 1366px) {

  .boutique {
    
    &__wrapper {

      padding: 0;
      justify-content: center;
      align-items: center;
      padding-bottom: 100px;

    }

    &__logo { 
      
      width: 90px;
      height: 90px;

    }

    &__cart {

      &--container {

        padding: 0;
        margin-top: 3vh;

      }

    }

    &__cards--wrapper {

      justify-content: center;
      align-items: center;

    }

    &__card {

      width: calc(((100vw - 10vw) / 3) - (3vw * 3));
      margin-left: 4vw;
      margin-bottom: 2rem;

    }

    &__card:nth-child(3n+3) {
      margin-right: -2vw;    
    }

  }

}