@use '../../styles/vars';

.domainepage {
  
  &-container {

    display: flex;
    width: 100%;
    
    background-image: url(../../assets/images/DomainePage-BG.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    color: vars.$color-white;
    font-size: 2rem;
    font-weight: bold;

    overflow: hidden;

  }

  &__section-text {
    
    z-index: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    text-align: right;
    width: calc(100% / 3 ) * 2;
    height: 100vh;
    overflow: auto;
    box-sizing: border-box;    
    padding-right: 2rem;
    padding-left: 15vw;
    padding: 2rem 2rem 2rem 15vw; 
    background-color: rgba(0,0,0, 0.5);

    &--logo {
      display: none;
    }

    p {
      font-size: 1.2rem;
      font-weight:300;
    }

    h1 {
      font-size: 3rem;
    }

  }

  &__section-photos {
    
    width: calc(100vw / 3 ) * 1;
    height: 100vh;
    margin: 0;
    padding: 0;
    box-shadow: 0 0 35px vars.$color-dgrey;;

    overflow: auto;
    background-color: vars.$color-pink;

    &--ul {

      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      list-style: none;
      margin: 0;

    }

    &--li {

      width: calc(((100vw / 3) / 3) - 2px);
      height: calc(((100vw / 3) / 3) - 2px);
      box-sizing: border-box;

      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;

      border-left: 6px solid  vars.$color-pink;
      border-top: 6px solid  vars.$color-pink;

    }

    &--open {

      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      border: none;
      margin: 0;
      padding: 0;
      min-width: 100%;
      min-height: 100%;
      mix-blend-mode: overlay;
      background-color: vars.$color-yellow;
      background: linear-gradient(-45deg, vars.$color-green,vars.$color-yellow,vars.$color-green);
      font-size: 2.3rem;
      color: vars.$color-white;
      opacity: 0;
      transition: visibility 0s, opacity 0.3s linear;

      cursor: pointer;

    }

    &--li:hover > button {
      opacity: 1;
    }
    
  }

}

.modal-photos {

  &__container {

    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    background-color: vars.$modal-bg-color;

  }

  &__content {

    position: relative;

  }

  &__photo {

    width: 70vw;
    border-radius: 1rem;
    box-shadow: 0 0 35px rgba(vars.$color-black, 0.5);

  }

  &__close {

    position: absolute;
    box-sizing: border-box;
    top: -50px;
    right: -50px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.3rem;
    height: 2.3rem;
    background-color: rgba(vars.$color-white, 0.6);
    border: none;
    box-shadow: 0 0 15px rgba(vars.$color-black, 0.7);
    border-radius: 50%;
    font-size: 1.6rem;
    font-weight: 900;
    color: vars.$color-brown;
    cursor: pointer;

  }

}

.modal-photos__container.enter-done {
  opacity: 1;
  pointer-events: visible;
}

.modal-photos__container.modal.exit {
  opacity: 0;
}

@import './responsive.scss';