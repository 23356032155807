@media only screen and (max-width: 480px) {

  .domainepage {

    &-container {
  
      display: flex;
      flex-direction: column;
      padding-bottom: calc(100vw/5);
    
      color: vars.$color-white;
      font-size: 2rem;
      font-weight: bold;
    
      overflow: unset;
    
    }
  
    &__section-text {

      align-items: center;  
      padding: 0;
      padding-bottom: 2rem;
      width: 100%;
      height: auto;

      text-align: unset;
      
      background-image: url(../../assets/images/DomainePage-10.jpg);
      background-repeat: no-repeat;
      background-position: top center;
      background-size: cover;
      background-attachment: scroll;

      &--logo {

        display: block;
        width: 100%;
        height: 30vh;
      
        background-image: url(../../assets/images/PageLogo-B.png);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;

      }

      h1 {
        display: none;
      }

      p {
        width: 80%;        
        padding: 0.8rem;
        box-shadow: 0 0 15px vars.$color-black;
        background-color: rgba(vars.$color-dgrey, .8);
        font-size: .9rem;
        line-height: 1.5rem;
        letter-spacing: 1px;
        color: vars.$color-white;

      }

      &--p-container {
        
        display: flex;
        width: 100%;

        &--right {
          justify-content: flex-end!important;
        }

      }

      &--p1 {

        text-align: left;

      }

      &--p2 {

        text-align: right;

      }

    }

    &__section-photos {

      display: flex;
      flex-direction: column;
      justify-content: center;      
      width: 100vw;
      height: auto;
      box-shadow: 0 0 15px vars.$color-dgrey;

      h1 {
        font-size: 1.5rem;
        text-transform: uppercase;
        border-radius: 0;
        padding: 1rem;
        background-color: vars.$color-pink;
        color: vars.$color-white;
        mix-blend-mode: normal;
      }

      &--ul {
        
        width: 100%;
        display: block;
        list-style: none;
        margin: 0;
      }
  
      &--li {
  
        width: 100%;
        height: calc((100vw/3)*2);
        box-sizing: unset;
        border: none;

      }

      &--open {

        display: none;

      }
  
    }

  }

}


@media only screen and (min-width: 481px) and (max-width: 800px) {

  .domainepage {

    &__section-text {

      justify-content: flex-start;
      width: calc(100% / 4 ) * 3;

      h1 {
        font-size: 1.7rem;;
      }

      p {
        font-size: 1rem;
        font-weight:300;
      }
    
    }

    &__section-photos {
      
      width: calc(100vw / 4 ) * 1;

      &--li {

        width: calc(((100vw / 4) / 1) - 6px);
        height: calc(((100vw / 4) / 1) - 6px);

      }

    }

  }

  .modal-photos {
  
    &__container {
  
      left: 0;
      top: 0;
      padding-top: 10px;
  
    }
  
    &__content {
  
      position: relative;
  
    }
  
    &__photo {
  
      width: 70vw;
      border-radius: .8rem;
      box-shadow: 0 0 35px rgba(vars.$color-black, 0.5);
  
    }
  
    &__close {
      top: -25px;
      right: -25px;
      width: 1.5rem;
      height: 1.5rem;
      font-size: .9rem;
      font-weight: 900;
  
    }
  
  }

}


@media only screen and (min-width: 801px) and (max-width: 1366px) {

  .domainepage {

    &__section-text {

      justify-content: flex-start;

      h1 {
        font-size: 1.8rem;;
      }

      p {
        font-size: .9rem;
        font-weight:300;
      }
    
    }

    &__section-photos {

      &--li {

        width: calc(((100vw / 3) / 2) - 2px);
        height: calc(((100vw / 3) / 2) - 2px);

      }

    }

  }

  .modal-photos {
  
    &__container {
  
      left: 0;
      top: 0;
      padding-top: 10px;
  
    }
  
    &__content {
  
      position: relative;
  
    }
  
    &__photo {
  
      width: 70vw;
      border-radius: .8rem;
      box-shadow: 0 0 35px rgba(vars.$color-black, 0.5);
  
    }
  
    &__close {
      top: -40px;
      right: -40px;
      width: 1.8rem;
      height: 1.8rem;
      font-size: 1.1rem;
      font-weight: 900;
  
    }
  
  }

}