@use './vars';

html, body {
  font-size: 18px;
  margin: 0;
  padding: 0;
  font-family: vars.$font;
  display: flex;
  min-height: 100vh;
}

.hidden {
  display: none;
}

h1 {

  margin: 0;
  padding: .3rem .8rem;
  border-radius: .6rem;
  font-size: 3.6rem;
  color: black;      
  background-color: vars.$color-white;
  mix-blend-mode: screen;

}

@media only screen and (orientation: landscape){

  h1 {
    padding: .7rem 1rem;
    margin-bottom: 2rem;
    font-size: 1.3rem;
    text-align: center;
    color: vars.$color-dgrey;      
    background-color: vars.$color-white;
    mix-blend-mode: screen;
  }

}