@use "../../styles/vars";

.wrapper {

  width: 100%;
  height: 100vh;
  padding: 0px;
  margin: 0;

}

.langNav {

  &__container {
    
    z-index: 10;
    position: fixed;
    display: flex; 
    box-sizing: border-box;
    align-items: flex-start;
    top: 0;
    left: 0;
    padding-left: 20px;
    padding-top: 30px;

  }

  &__button {
    width: 2.3rem;
    height: 2.3rem;
    border: none;
    margin-left: .7rem;
    background-color: transparent;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 50%;
    cursor: pointer;

    &--fr {
      background-image: url(../../assets/icons/flag-fr.png);
    
      &-unselected {
        background-image: url(../../assets/icons/flag-fr-grey.png);
      }
      
    }

    &--en {
      background-image: url(../../assets/icons/flag-en.png);
    
      &-unselected {
        background-image: url(../../assets/icons/flag-en-grey.png);
      }

    }

  }

}

@import './responsive.scss';